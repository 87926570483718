// import { ssoClient } from '@triascloud/sso-client';

/** @name 是否登录 */
// const hasLogin = () => ssoClient.getTokenSync();

/** @name 是否分享 */
export const checkHasShare = route => {
  if (route.path.indexOf('share') > -1) {
    return true;
  }
  // if (!hasLogin()) {
  //   return true;
  // }
  return false;
};

/** @name 图表颜色数组 */
export const chartColors = [
  '#5FE3FF',
  '#BFF3B4',
  '#6AC5BE',
  '#5F93E6',
  '#895BFF',
  '#E25DAE',
  '#4F7FDF',
  '#F19A91',
  '#8E95E5',
  '#DAF4FF',
  '#CF6FE6',
  '#B25BFF',
  '#C6FFAA',
  '#C39CE1',
  '#E0E5DB',
  '#506DFF',
];
