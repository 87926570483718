<template>
  <div :class="$style.header">
    <div :class="$style.left"></div>
    <div :class="$style.center">
      {{ $t('hat.screen.title') }}
    </div>
    <div :class="$style.right">
      <div :class="$style.time">
        <span>{{ date.date }}</span>
        <span>{{ date.week }}</span>
        <span>{{ time }}</span>
      </div>
      <div
        :class="$style.icon"
        @click.stop="handleShare()"
        v-if="$p.action('IOT-SCREEN-SHARE')"
      >
        <a-icon type="share-alt" />
      </div>
      <div :class="$style.icon" @click="handleFullScreen">
        <a-icon type="fullscreen" v-if="!isFullScreen" />
        <a-icon type="fullscreen-exit" v-else />
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import moment from 'moment';
import { createFormModal, createModal } from '@triascloud/x-components';
import ShareForm from './share/share-form.vue';
import ShareBox from './share/share-box.vue';
import { fullScreen } from '@/utils/common.js';

@Component()
export default class HatScreenHeader extends Vue {
  get date() {
    const now = moment(this.now);
    return {
      week: {
        1: this.$t('hat.screen.Mon'),
        2: this.$t('hat.screen.Tue'),
        3: this.$t('hat.screen.Wed'),
        4: this.$t('hat.screen.Thu'),
        5: this.$t('hat.screen.Fri'),
        6: this.$t('hat.screen.Sat'),
        7: this.$t('hat.screen.Sun'),
      }[now.format('e')],
      date: now.format('YYYY.MM.DD'),
    };
  }
  get time() {
    return moment(this.now).format('HH:mm:ss');
  }
  interval = 0;
  now = Date.now();
  beforeDestroy() {
    clearInterval(this.interval);
  }
  created() {
    this.interval = setInterval(() => {
      this.now = Date.now();
    }, 1000);
  }
  @InjectReactive('formDataLabel') formLabel;
  async handleShare() {
    const data = {
      projectId: '123',
      projectName: '246',
    };
    const result = await createFormModal(
      () => <ShareForm data={data} formLabel={this.formLabel} />,
      {
        width: this.$size(628),
        title: this.$t('hat.screen.share'),
        okText: this.$t('hat.screen.GenerateLink'),
        maskClosable: false,
      },
    );
    const modal = createModal(
      () => <ShareBox shareInfo={result} onClose={() => modal.handleClose()} />,
      {
        title: this.$t('hat.screen.share'),
        width: this.$size(420),
        maskClosable: false,
      },
    );
  }
  isFullScreen = false;
  handleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    fullScreen();
  }
}
</script>
<style lang="less" module>
.header {
  height: 0.62rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 99;
  top: 0;
  background: url('~@/assets/images/smart-hat/screen/header.png') no-repeat
    center;
  background-size: 100% 100%;
  color: #ffffff;
  .left,
  .center,
  .right {
    width: 30%;
    display: flex;
  }
  .left {
    align-items: center;
    // margin-top: 0.08rem;
  }
  .center {
    justify-content: center;
    // align-items: center;
    line-height: 0.5rem;
    letter-spacing: 3px;
    font-size: 0.24rem;
    font-weight: 600;
  }
  .right {
    justify-content: flex-end;
    align-items: center;
    margin-right: 0.24rem;
    // margin-top: 0.08rem;
    .icon {
      margin-left: 0.16rem;
      font-size: 0.19rem;
      display: inline-block;
      text-align: center;
      cursor: pointer;
    }
    .time {
      margin-right: 0.14rem;
      span {
        font-size: 0.16rem;
        margin-right: 0.08rem;
      }
    }
  }
}
</style>
