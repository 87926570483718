<template>
  <div ref="rankChartRef" style="width: 100%;height: 100%;"></div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
// import rankTitle from '@/assets/images/smart-hat/rank-title.png';
import { nowSize } from '@/utils/common';
import { Emitter } from '@/utils';
import { omittedText } from '@/views/hat/utils';

@Component()
export default class RankChartIndex extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  chartData;

  @Watch('chartData', { deep: true, immediate: true })
  async chartDataChange(val) {
    if (val?.length) {
      await this.$nextTick();
      this.initChart();
    } else {
      this.chartInstance?.dispose();
      this.chartInstance = null;
    }
  }

  /**@type {echarts.EChartsCoreOption} */
  get options() {
    if (!this.chartData) return {};
    const yAxisMap = new Map();
    this.chartData?.forEach(item => {
      yAxisMap.set(item.name, item.value);
    });

    return {
      tooltip: {
        appendToBody: true,
        trigger: 'axis',
        borderWidth: 0,
        textStyle: {
          fontSize: nowSize(12),
          color: '#fff',
        },
        backgroundColor: 'rgba(9, 77, 143, 0.8)',
      },
      legend: {
        show: false,
      },
      grid: {
        left: '3%',
        right: '5%',
        top: 0,
        bottom: 0,
        containLabel: true,
      },
      dataZoom: [
        {
          type: 'inside',
          orient: 'vertical',
          zoomLock: true,
          start: 0,
          end:
            this.chartData.length > 5 ? (5 / this.chartData.length) * 100 : 100,
        },
      ],
      xAxis: {
        type: 'value',
        show: false,
      },
      yAxis: [
        {
          type: 'category',
          splitLine: false,
          inverse: true,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            color: 'rgba(251,253,255,0.8)',
            formatter: value => {
              return `{name|${omittedText(value, 4)}}`;
            },
            rich: {
              name: {
                // width: nowSize(68),
                height: nowSize(18),
                align: 'center',
                padding: [nowSize(3), 0, 0, 0],
                verticalAlign: 'middle',
                fontSize: nowSize(12),
                borderRadius: nowSize(3),
                // backgroundColor: {
                //   image: rankTitle,
                // },
              },
            },
          },
          z: 10,
          data: this.chartData.map(item => item.name),
        },
        {
          type: 'category',
          position: 'right',
          inverse: true,
          splitLine: false,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            color: 'rgba(251,253,255,0.8)',
            fontSize: nowSize(12),
            formatter: value => {
              return yAxisMap.get(value) + '小时';
            },
          },
          z: 10,
          data: this.chartData.map(item => item.name),
        },
      ],
      series: [
        {
          type: 'bar',
          stack: 'total',
          showBackground: true,
          barWidth: nowSize(10),
          color: '#6BF2FF',
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)',
          },
          label: {
            show: false,
            position: 'right',
          },
          emphasis: {
            focus: 'series',
          },
          data: this.chartData.map(item => {
            return {
              name: item.name,
              value: item.value,
              id: item.id,
            };
          }),
        },
      ],
    };
  }

  /**@type {echarts.ECharts} */
  chartInstance = null;
  initChart() {
    if (!this.chartInstance) {
      this.chartInstance = echarts.init(this.$refs.rankChartRef);
    }

    this.chartInstance.setOption(this.options);
    this.chartInstance.off('click');
    this.chartInstance.on('click', params => {
      this.$emit('click-chart', params);
    });
  }

  resize() {
    this.chartInstance?.resize({
      width: 'auto',
      height: 'auto',
    });
  }

  async mounted() {
    this.initChart();
    Emitter.$on('bodyResize', this.resize);
  }
  beforeDestroy() {
    Emitter.$off('bodyResize', this.resize);
  }
}
</script>
