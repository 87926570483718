<template>
  <div :class="$style.wrapper">
    <screen-map ref="screenMap" :searchParam="searchForm" />
    <screen-header v-if="showHeader" />
    <div
      :class="[$style.left, showHeader ? '' : $style.hiddenHeader]"
      :style="`transform: translateX(${showLeft ? '0' : '-3.95rem'});`"
    >
      <div :class="$style.card" style="height: 24.6%;">
        <statistics ref="statistics" />
      </div>
      <div :class="$style.card" style="height: 37.7%;">
        <total-duration />
      </div>
      <div :class="$style.card" style="height: 37.7%;">
        <time-proportion />
      </div>
    </div>
    <div
      :class="[$style.right, showHeader ? '' : $style.hiddenHeader]"
      :style="`transform: translateX(${showRight ? '0' : '3.95rem'});`"
    >
      <div :class="$style.card" style="height: 37.7%;">
        <departure-ranking />
      </div>
      <div :class="$style.card" style="height: 37.7%;">
        <equipment-event />
      </div>
      <div :class="$style.card" style="height: 24.6%;">
        <focus-members />
      </div>
    </div>
    <div :class="$style.globalSelect">
      <a-select
        class="hat-screen-select"
        dropdownClassName="hat-screen-select-dropdown"
        v-model="searchForm.searchDayEnum"
        :options="dayList"
        @change="handleChange"
      >
        <a-icon
          slot="suffixIcon"
          type="caret-down"
          style="font-size: 0.16rem; color: #ffffff;"
        />
      </a-select>
      <a-select
        class="hat-screen-select"
        dropdownClassName="hat-screen-select-dropdown"
        :options="projectList"
        v-model="searchForm.projectIds"
        @change="handleProjectChange"
        :placeholder="$t('hat.screen.projects')"
      >
        <a-icon
          slot="suffixIcon"
          type="caret-down"
          style="font-size: 0.16rem; color: #ffffff;"
        />
      </a-select>
      <a-select
        class="hat-screen-select"
        dropdownClassName="hat-screen-select-dropdown"
        mode="multiple"
        option-filter-prop="children"
        :filter-option="true"
        :maxTagCount="1"
        :maxTagTextLength="2"
        v-model="searchForm.groupIdList"
        :options="groupList"
        @change="handleChange"
        :allowClear="true"
        :placeholder="$t('hat.screen.equipments')"
      >
        <a-icon slot="suffixIcon" type="smile" />
      </a-select>
    </div>
    <screen-footer ref="screenFooter" />
    <div @click="packUp" :class="$style.packUp">
      <a-icon :type="showLeft ? 'caret-left' : 'caret-right'" theme="filled" />
      <a-icon :type="showLeft ? 'caret-right' : 'caret-left'" theme="filled" />
    </div>
  </div>
</template>
<script>
import { Component, Vue, ProvideReactive } from 'vue-property-decorator';
import ScreenMap from './components/map/index.vue';
import LayoutCard from './components/layout-card.vue';
import { flexDpr } from '@/assets/js/flex-dpr.js';
import Statistics from './components/statistics.vue';
import TotalDuration from './components/total-duration.vue';
import TimeProportion from './components/time-proportion.vue';
import DepartureRanking from './components/departure-ranking.vue';
import EquipmentEvent from './components/equipment-event.vue';
import FocusMembers from './components/focus-members.vue';
import ScreenHeader from './components/screen-header.vue';
import ScreenFooter from './components/screen-footer.vue';
import { getGroupList } from '@/services/smart-hat/screen';
import { getProjectList } from '@/services/smart-hat/device-management';
import {
  getGroupList as getShareGroupList,
  getProjectList as getShareProjectList,
} from '@/services/smart-hat/screen-share';
import { checkHasShare } from '@/views/hat/screen/utils';
import { Emitter } from '@/utils';
import { debounce } from 'lodash';

flexDpr();
@Component({
  components: {
    ScreenMap,
    LayoutCard,
    Statistics,
    TotalDuration,
    TimeProportion,
    DepartureRanking,
    EquipmentEvent,
    FocusMembers,
    ScreenHeader,
    ScreenFooter,
  },
})
export default class HatScreen extends Vue {
  resizeObserver = null;
  timer = null;
  created() {
    this.setCssVariables();
    this.setHeader();
  }
  async mounted() {
    await this.getGroupList();
    await this.getProjectList();
    this.initData();
    // 监听body变化
    this.resizeObserver = new ResizeObserver(
      debounce(() => Emitter.$emit('bodyResize'), 100, { leading: true }),
    );
    // this.resizeObserver = new ResizeObserver(() => Emitter.$emit('bodyResize'));
    this.resizeObserver.observe(document.body);
    this.timer = setInterval(() => {
      this.$refs.screenFooter.getTypeStatic(this.searchForm);
      this.$refs.statistics.getTarget(this.searchForm);
      this.$refs.screenMap.updatePosition(this.searchForm);
      Emitter.$emit('searchChange', this.searchForm);
    }, 30000);
  }
  beforeDestroy() {
    this.removeCssVariables();
    clearInterval(this.timer);
    this.resizeObserver.unobserve(document.body);
  }

  @ProvideReactive('formDataLabel')
  get formDataLabel() {
    const groupList = [];
    const projectList = [];
    this.searchForm.groupIdList.forEach(item => {
      let prevFind = this.groupList.find(v => v.value === item);
      prevFind && groupList.push(prevFind);
    });
    let prevFind = this.projectList.find(
      v => v.value === this.searchForm.projectIds,
    );
    prevFind && projectList.push(prevFind);
    return {
      groupList,
      projectList,
    };
  }

  @ProvideReactive('searchForm')
  searchForm = {
    groupIdList: [],
    searchDayEnum: 'THIRTY_DAY',
    projectIds: '',
  };
  get dayList() {
    return [
      {
        label: this.$t('hat.screen.today'),
        value: 'CURRENT_DAY',
      },
      {
        label: this.$t('matterwebset.screenmanage.last7'),
        value: 'SEVEN_DAY',
      },
      {
        label: this.$t('matterwebset.screenmanage.last30'),
        value: 'THIRTY_DAY',
      },
    ];
  }
  groupList = [];
  async getGroupList(projectIds) {
    try {
      const groupList = !checkHasShare(this.$route)
        ? await getGroupList({
            projectIds,
          })
        : await getShareGroupList({
            shareId: this.$route.params.id,
            password: this.$route.params.password,
            projectIds,
          });
      this.groupList = groupList.map(item => ({
        label: item.deviceGroupName,
        value: item.pkId,
      }));
      // this.groupList = this.groupList.concat(list);
    } catch (e) {
      return false;
    }
  }
  projectList = [];
  async getProjectList(input = undefined) {
    const params = {
      current: 1,
      size: 100,
      input,
    };
    const { records } = !checkHasShare(this.$route)
      ? await getProjectList(params)
      : await getShareProjectList({
          shareId: this.$route.params.id,
          password: this.$route.params.password,
          ...params,
        });
    this.projectList = records.map(item => ({
      label: item.pmName,
      value: item.pkId,
    }));
    this.projectList.unshift({
      label: this.$t('hat.screen.projects'),
      value: '',
    });
  }
  handleProjectChange(id) {
    this.searchForm.groupIdList = [];
    this.getGroupList(id);
    this.initData();
  }
  initData() {
    this.$refs.screenMap.initData(this.searchForm);
    this.$refs.screenFooter.getTypeStatic({ ...this.searchForm, reset: true });
    this.$refs.statistics.getTarget(this.searchForm);
    Emitter.$emit('searchChange', this.searchForm);
  }
  handleChange() {
    this.initData();
  }
  showLeft = true;
  showRight = true;
  packUp() {
    this.showLeft = !this.showLeft;
    this.showRight = !this.showRight;
  }
  showHeader = true;
  setHeader() {
    if (sessionStorage['SHOW_HEADER'] === 'HIDDEN') {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  }
  oldBodyClassName = '';
  // 更换弹窗的变量
  setCssVariables() {
    let className = document.body.getAttribute('class');
    this.oldBodyClassName = className;
    window.document.body.setAttribute(
      'class',
      className + ' ' + this.$style.cssVariables,
    );
  }
  removeCssVariables() {
    window.document.body.setAttribute('class', this.oldBodyClassName);
  }
}
</script>
<style lang="less" module>
// 更换弹窗的变量
.cssVariables {
  --modal-bg: rgba(33, 52, 68);
  --font: #999;
  --block-bg: rgba(33, 52, 68, 0.8);
  --table-row: rgba(33, 52, 68, 0.4);
  --table-row-stripe: rgba(7, 12, 64, 0.4);
  --form-disabled: rgba(7, 12, 64, 0.4);
  --tag: rgba(7, 38, 44, 0.8);
  --body-bg: rgba(7, 38, 44, 0.8);
  --form-border: #5c5c5c;
  --table-border: #5c5c5c;
  --border: #5c5c5c;
  --scroll-bg: rgba(7, 38, 44, 0.8);
  --scroll-track: rgba(7, 12, 64, 0.4);
  --tag: rgba(70, 70, 70, 0.8);
  // ::-webkit-scrollbar-corner——当同时有垂直滚动条和水平滚动条时交汇的部分。通常是浏览器窗口的右下角。
  ::-webkit-scrollbar-corner {
    background-color: rgba(7, 12, 64, 0.4);
  }
  :global {
    .ant-calendar-picker,
    .ant-select-enabled {
      background-color: hsla(0, 0%, 100%, 0.1) !important;
    }
  }
}
html {
  overflow: hidden;
}
.wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  .map {
    height: 100%;
    width: 100%;
  }
  .mask {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000;
    z-index: 99;
  }
  .box(@w, @h) {
    width: @w;
    height: @h;
  }
  .hiddenHeader {
    // height: 92vh !important;
    // top: 3vh !important;
  }
  & > .left,
  & > .right {
    .box(3.8rem, 89.5vh);
    transition: transform 0.5s;
    background: rgba(7, 38, 64, 0.8);
    border-radius: 6px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    position: absolute;
    top: 7.3vh;
    z-index: 100;
    .card {
      // flex: 1;
      overflow: hidden;
    }
  }
  & > .left {
    padding: 0.1rem;
    left: 0.15rem;
  }
  & > .right {
    padding: 0.1rem;
    right: 0.15rem;
  }
  & > .globalSelect {
    position: absolute;
    text-align: center;
    z-index: 100;
    left: 0.16rem;
    top: 0.18rem;
  }
  & > .packUp {
    height: 0.8rem;
    width: 0.8rem;
    padding: 0.16rem 0 0 0.08rem;
    border-radius: 50%;
    background: rgba(0, 183, 255, 0.4);
    position: absolute;
    cursor: pointer;
    z-index: 101;
    bottom: -0.4rem;
    right: -0.4rem;
    color: #ffffff;
  }
}
:global {
  .hat-screen-select {
    width: 1.5rem;
    height: 0.38rem;
    overflow: hidden;
    font-size: 0.14rem;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    .ant-select-selection--single {
      border-radius: 0;
      height: 0.32rem;
    }
    .ant-select-selection__rendered {
      color: #ffffff;
      line-height: 0.32rem;
    }
    .ant-select-search__field__placeholder,
    .ant-select-selection__placeholder {
      color: #ffffff;
    }
    .ant-select-selection,
    .ant-select-focused,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      border-color: transparent;
      box-shadow: none;
    }
    .anticon-down {
      color: #fff;
    }
    .ant-select-clear-icon {
      color: #ffffff;
    }
    & + * {
      margin-left: 0.05rem;
    }
    .ant-select-selection--multiple .ant-select-selection__choice {
      background-color: transparent;
      color: #ffffff;
      svg {
        color: #ffffff;
      }
    }
  }
  .hat-screen-select-dropdown {
    background: #364a5b;
    .ant-select-dropdown-menu {
      padding: 0;
    }
    .ant-select-dropdown-menu::-webkit-scrollbar {
      margin-right: 3px;
      width: 6px;
      height: 6px;
      background: transparent;
    }
    .ant-select-dropdown-menu::-webkit-scrollbar-thumb {
      margin-right: 3px;
      background: #214d71;
      border-radius: 10px;
    }
    .ant-select-dropdown-menu::-webkit-scrollbar-track {
      border-radius: 10px;
      background: transparent !important;
    }
    .ant-select-dropdown-menu-item {
      color: #fff;
      font-size: 0.14rem;
      svg {
        font-size: 0.16rem;
        color: #2c5084;
      }
    }
    .ant-select-dropdown-menu-item-selected {
      svg {
        color: #fff;
      }
    }
    .ant-select-dropdown-menu-item:hover {
      background: #286fad;
    }
    .ant-select-dropdown-menu-item-selected {
      background: #286fad;
    }
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background: #286fad;
    }
    .x-empty-content--label {
      color: #9ac0d9;
      font-size: 0.12rem;
    }
    .ant-select-dropdown-menu-item-selected {
      background: #286fad;
      color: #fff;
    }
  }
}
</style>
