<template>
  <div ref="scatterChartRef" style="width: 100%;height: 100%;"></div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { nowSize } from '@/utils/common';
import { Emitter } from '@/utils';
import { chartColors } from '../utils';

@Component()
export default class ScatterChartIndex extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  chartData;

  @Watch('chartData', { deep: true, immediate: true })
  async chartDataChange() {
    await this.$nextTick();
    this.initChart();
  }

  /**@type {echarts.EChartsCoreOption} */
  get options() {
    if (!this.chartData) return {};
    return {
      color: chartColors,
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      xAxis: {
        type: 'value',
        show: false,
        splitLine: {
          show: false,
        },
        max: 1000,
      },
      yAxis: {
        type: 'value',
        show: false,
        splitLine: {
          show: false,
        },
        max: 1000,
      },
      tooltip: {
        appendToBody: true,
        textStyle: {
          fontSize: nowSize(12),
          color: '#fff',
        },
        // position: point => {
        //   return [point[0], 0];
        // },
        borderWidth: 0,
        backgroundColor: 'rgba(9, 77, 143, 0.8)',
        formatter: param => {
          var index = param.seriesIndex;
          const item = this.chartData[index];
          return [
            item.userName,
            `缺勤：${item.absenceFromWorkSum ?? 0}`,
            `离岗：${item.offDutySum ?? 0}`,
            `早退：${item.leaveEarlySum ?? 0}`,
            `异常静止：${item.unusuallyStillSum ?? 0}`,
          ].join('<br />');
        },
      },
      series: this.chartData.map((item, index) => {
        const arr = [
          [500, 500],
          [300, 300],
          [300, 700],
          [690, 450],
          [500, 870],
          [610, 780],
          [500, 200],
          [420, 800],
          [680, 630],
          [600, 280],
        ];
        // 渐变颜色
        // const colors = [
        //   ['rgba(254, 189, 125, 1)', 'rgba(254, 189, 125, 0)'],
        //   ['rgba(153, 1, 1, 1)', 'rgba(153, 1, 1, 0)'],
        //   ['rgba(1, 194, 255, 1)', 'rgba(1, 194, 255, 0)'],
        //   ['rgba(1, 194, 255, 1)', 'rgba(1, 194, 255, 0)'],
        //   ['rgba(254, 189, 125, 1)', 'rgba(254, 189, 125, 0)'],
        //   ['rgba(243, 253, 125, 1)', 'rgba(243, 253, 125, 0)'],
        //   ['rgba(150, 74, 135, 1)', 'rgba(150, 74, 135, 0)'],
        //   ['rgba(150, 74, 135, 1)', 'rgba(150, 74, 135, 0)'],
        //   ['rgba(150, 74, 135, 1)', 'rgba(150, 74, 135, 0)'],
        //   ['rgba(150, 74, 135, 1)', 'rgba(150, 74, 135, 0)'],
        // ];
        return {
          data: [arr[index]],
          type: 'scatter',
          label: {
            show: true,
            formatter: function() {
              return index >= 6 ? '' : item.userName;
            },
            color: '#fff',
            fontSize: nowSize(16),
          },
          symbolSize: () => {
            return nowSize([80, 60, 60, 30, 30, 30, 20, 20, 20, 20][index]);
          },
          itemStyle: {
            // borderColor: colors[index][0],
            // borderWidth: nowSize(1),
            // color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
            //   {
            //     offset: 0,
            //     color: colors[index][0],
            //   },
            //   {
            //     offset: 1,
            //     color: colors[index][1],
            //   },
            // ]),
          },
        };
      }),
    };
  }

  /**@type {echarts.ECharts} */
  chartInstance = null;
  initChart() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
      this.chartInstance = null;
    }
    this.chartInstance = echarts.init(this.$refs.scatterChartRef);
    this.chartInstance.setOption(this.options);
  }

  resize() {
    this.chartInstance.resize({
      width: 'auto',
      height: 'auto',
    });
  }

  async mounted() {
    this.initChart();
    Emitter.$on('bodyResize', this.resize);
  }
  beforeDestroy() {
    Emitter.$off('bodyResize', this.resize);
  }
}
</script>
