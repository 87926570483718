<template>
  <a-table
    :class="$style.box"
    :columns="columns"
    :data-source="tableData"
    :pagination="pagination"
    :row-key="record => record.deviceId"
    :loading="loading"
    @change="handlePagination"
  >
  </a-table>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { entryOrLeaveDetails } from '@/services/smart-hat/screen';
import { shareEntryOrLeaveDetails } from '@/services/smart-hat/screen-share';
import { nowSize } from '@/utils/common';

@Component()
export default class DialogPersonTotalDuration extends Vue {
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  loading = false;
  tableData = [];
  get columns() {
    const list = [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('hat.clockRecord.index'),
        align: 'center',
        fixed: 'left',
        width: nowSize(80),
      },
      {
        align: 'left',
        title: '日期',
        dataIndex: 'startTime',
        ellipsis: true,
        customRender: text =>
          text ? this.$moment(text).format('YYYY-MM-DD') : '',
      },
      {
        align: 'left',
        title: '首次入场时间和地区',
        ellipsis: true,
        customRender: (_, record) =>
          record.startTime ? (
            <div>
              <span class={this.$style.pRange}>
                {this.$moment(record.startTime).format('HH:mm:ss')}
              </span>
              <span class={this.$style.pRange}>
                {record.startGeofenceName ? record.startGeofenceName : '-'}
              </span>
            </div>
          ) : (
            ''
          ),
      },
      {
        align: 'left',
        title: '最后在场时间和地区',
        ellipsis: true,
        customRender: (_, record) =>
          record.endTime ? (
            <div>
              <span class={this.$style.pRange}>
                {this.$moment(record.endTime).format('HH:mm:ss')}
              </span>
              <span class={this.$style.pRange}>
                {record.endGeofenceName ? record.endGeofenceName : '-'}
              </span>
            </div>
          ) : (
            ''
          ),
      },
      {
        title: '累计在场时长',
        dataIndex: 'enterDuration',
        ellipsis: true,
        customRender: text => (text ? text + '小时' : ''),
      },
      {
        title: '累计离场时长',
        dataIndex: 'leaveDuration',
        ellipsis: true,
        customRender: text => (text === 0 || text > 0 ? text + '小时' : '-'),
      },
    ];
    if (!this.hasShare) {
      list.push({
        title: this.$t('enterpriseManagement.camera.tableColumn.operation'),
        fixed: 'right',
        key: 'operation',
        ellipsis: true,
        customRender: (_, record) => (
          <span
            style={{
              color: 'var(--font-active)',
              cursor: 'pointer',
            }}
            onClick={() => this.handleDeviceItem(record)}
          >
            详情
          </span>
        ),
      });
    }
    return list;
  }
  @Prop({ type: String }) dayEnum;
  @Prop({ type: String }) id;
  @Prop({ type: Object }) shareParams;
  @Prop({ type: Boolean }) hasShare;
  async getData() {
    this.loading = true;
    try {
      const params = {
        searchDayEnum: this.dayEnum,
        userId: this.id,
        current: this.pagination.current,
        size: this.pagination.pageSize,
      };
      const { records, total } = this.hasShare
        ? await shareEntryOrLeaveDetails(params, this.shareParams)
        : await entryOrLeaveDetails(params);
      this.tableData = records;
      this.pagination.total = total;
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
  handleDeviceItem(item) {
    const route = this.$router.resolve({
      path: `/hat/clock?key=2&userId=${item.userId}&date=${this.dayEnum}`,
    });
    window.open(route.href, '_blank');
  }
  roleNames(list) {
    let str = list.reduce((v, a) => v + a.roleName + '、', '');
    if (str.slice(-1) === '、') {
      str = str.slice(0, -1);
    }
    return str;
  }
  handlePagination(data) {
    this.pagination = data;
    this.getData();
  }
  mounted() {
    this.getData();
  }
}
</script>
<style lang="less" module>
.box {
  :global {
    td.ant-table-row-cell-ellipsis {
      vertical-align: middle;
    }
  }
}
.pRange {
  height: 0.16rem;
  line-height: 0.16rem;
  display: block;
}
</style>
