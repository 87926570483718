<template>
  <LayoutCard :title="$t('hat.screen.statistics')">
    <div :class="$style.countList">
      <div
        :class="$style.item"
        v-for="(item, index) in countList"
        :key="index"
        @click="handleClick(item)"
      >
        <div :class="$style.left">
          <a-icon :type="item.icon" theme="filled" :class="$style.icon" />
        </div>
        <div :class="$style.right">
          <span :class="$style.name">{{ item.name }}</span>
          <div :class="$style.line"></div>
          <div :class="$style.number">
            <span :title="item.count">{{ item.count }}</span>
            <span>{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div>
  </LayoutCard>
</template>
<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import LayoutCard from './layout-card.vue';
import { getTarget } from '@/services/smart-hat/screen';
import { getTarget as getShareTarget } from '@/services/smart-hat/screen-share';
import { checkHasShare } from '@/views/hat/screen/utils';
import { createModal } from '@triascloud/x-components';
import DialogOnline from './dialog/dialog-online.vue';
import DialogPresence from './dialog/dialog-presence.vue';
import DialogAvgOnTime from './dialog/dialog-avg-on-time.vue';
import DialogDeviceEvents from './dialog/dialog-device-events.vue';
import { nowSize } from '@/utils/common';

@Component({
  components: {
    LayoutCard,
  },
})
export default class HatScreenStatistics extends Vue {
  mounted() {}
  countList = [];
  async getTarget(params) {
    const data = !checkHasShare(this.$route)
      ? await getTarget({
          groupIdList: params.groupIdList.toString(),
          searchDayEnum: params.searchDayEnum,
          projectIds: params.projectIds,
        })
      : await getShareTarget(
          {
            groupIdList: params.groupIdList.toString(),
            searchDayEnum: params.searchDayEnum,
            projectIds: params.projectIds,
          },
          {
            shareId: this.$route.params.id,
            password: this.$route.params.password,
          },
        );
    this.countList = [
      {
        icon: 'cloud',
        name: this.$t('hat.screen.online'),
        count: this.formatValue(data.onlineDeviceCount),
        unit: this.$t('hat.screen.devices'),
      },
      {
        icon: 'file',
        name: this.$t('hat.screen.present'),
        count: this.formatValue(data.presenceCount),
        unit: this.$t('hat.screen.wearers'),
      },
      {
        icon: 'clock-circle',
        name: this.$t('hat.screen.avgPresentPeriod'),
        count: this.formatValue(data.avgOnTime),
        unit: this.$t('hat.screen.hours'),
      },
      {
        icon: 'pie-chart',
        name: this.$t('hat.screen.deviceEvents'),
        count: this.formatValue(data.deviceEventCount),
        unit: this.$t('hat.screen.item'),
      },
    ];
  }
  formatValue(value = '') {
    return value === -1 || value === '' ? '--' : value;
  }

  handleClick(item) {
    switch (item.icon) {
      case 'cloud':
        this.dialogOnline();
        break;
      case 'file':
        this.dialogPresence();
        break;
      case 'clock-circle':
        this.dialogAvgOnTime();
        break;
      case 'pie-chart':
        this.dialogDeviceEvents();
        break;
    }
  }
  @InjectReactive('searchForm') form;
  get hasShare() {
    return checkHasShare(this.$route);
  }
  get shareParams() {
    return {
      shareId: this.$route.params.id,
      password: this.$route.params.password,
    };
  }
  dialogOnline() {
    createModal(
      () => (
        <DialogOnline
          form={this.form}
          hasShare={this.hasShare}
          shareParams={this.shareParams}
        />
      ),
      {
        title: '在线设备明细',
        width: nowSize(1300),
      },
    );
  }
  dialogPresence() {
    createModal(
      () => (
        <DialogPresence
          form={this.form}
          hasShare={this.hasShare}
          shareParams={this.shareParams}
        />
      ),
      {
        title: '在场人员明细',
        width: nowSize(1200),
      },
    );
  }
  dialogAvgOnTime() {
    createModal(
      () => (
        <DialogAvgOnTime
          form={this.form}
          hasShare={this.hasShare}
          shareParams={this.shareParams}
        />
      ),
      {
        title: '平均在场时长明细',
        width: nowSize(1200),
      },
    );
  }
  dialogDeviceEvents() {
    createModal(
      () => (
        <DialogDeviceEvents
          form={this.form}
          hasShare={this.hasShare}
          shareParams={this.shareParams}
        />
      ),
      {
        title: '设备事件明细',
        width: nowSize(1200),
      },
    );
  }
}
</script>
<style lang="less" module>
.countList {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  .item {
    display: flex;
    width: 48%;
    cursor: pointer;
    .left {
      width: 0.6rem;
      height: 0.6rem;
      background: url('~@/assets/images/smart-hat/screen/icon-bg.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 0.6rem;
      .icon {
        color: #fff;
        font-size: 0.18rem;
      }
    }
    .right {
      width: 1rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 0.1rem;
      .name {
        color: rgba(251, 253, 255, 0.8);
        font-size: 0.14rem;
      }
      .line {
        width: 100%;
        height: 0.06rem;
        margin: 0.04rem 0;
        background: url('~@/assets/images/smart-hat/screen/icon-line.png')
          no-repeat;
        background-size: 100% 100%;
      }
      .number {
        width: 100%;
        height: auto;
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding-left: 0.06rem;
        background: linear-gradient(
          to right,
          rgba(1, 107, 136, 0.669),
          rgba(0, 0, 0, 0)
        );
        & > span:first-of-type {
          display: inline-block;
          max-width: 0.8rem;
          overflow: hidden;
          // text-overflow: ellipsis;
          font-size: 0.2rem;
          color: #6bf2ff;
        }
        & > span:last-of-type {
          font-size: 0.13rem;
          color: rgba(251, 253, 255, 0.8);
          margin-left: 0.06rem;
        }
      }
    }
  }
}
</style>
