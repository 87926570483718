import request from '../request';

const serviceName = '/iot-smart-helmet/screen';

/** @name 获取设备列表 */
export function getDeviceList(data) {
  return request(`${serviceName}/list`, {
    method: 'GET',
    body: data,
  });
}

/** @name 获取底部类型数据 */
export function getTypeStatic(data) {
  return request(`${serviceName}/count`, {
    method: 'GET',
    body: data,
  });
}

/** @name 获取关键指标 */
export function getTarget(data) {
  return request(`${serviceName}/target`, {
    method: 'GET',
    body: data,
  });
}

/** @name 下拉选择编组列表 */
export function getGroupList(data) {
  return request(`${serviceName}/loadDeviceGroup`, {
    method: 'GET',
    body: data,
  });
}

/**
 * 在线时长统计
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function onlineDurationStatistics(data) {
  return request(`${serviceName}/statistics/onSiteBoard`, {
    method: 'GET',
    body: data,
  });
}

/**
 * 离场统计
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function departureRanking(data) {
  return request(`${serviceName}/statistics/offSiteBoard`, {
    method: 'GET',
    body: data,
  });
}

/**
 * 时长占比统计
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum 按人/按组
 * @param {'CURRENT_DAY' | 'SEVEN_DAY' | 'THIRTY_DAY'} data.searchDayEnum 时间范围
 * @param {string} data.groupIdList 编组IDS id,id,id
 * @returns
 */
export function durationStatistics(data) {
  return request(`${serviceName}/statistics/proportion`, {
    method: 'GET',
    body: data,
  });
}

/**
 * 设备事件
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function deviceEvent(data) {
  return request(`${serviceName}/event`, {
    method: 'GET',
    body: data,
  });
}

/**
 * 重点关注成员
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function focusMemberStatistics(data) {
  return request(`${serviceName}/focusMember`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 在场-离场明细
 */
export function entryOrLeaveDetails(data) {
  return request(`${serviceName}/entryOrLeaveDetails`, {
    method: 'GET',
    body: data,
  });
}

/** @name 电子围栏列表 */
export function getGeoFenceZoneList(data) {
  return request(`${serviceName}/geofence/zone/list`, {
    method: 'GET',
    body: data,
  });
}
