<template>
  <div ref="pieChatrRef" style="width: 100%;height: 100%;"></div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { nowSize } from '@/utils/common';
import { Emitter } from '@/utils';
import { chartColors } from '../utils';

@Component()
export default class PieChartIndex extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  chartData;
  @Prop({ type: String, default: 'center' }) left;
  @Prop({ type: String, default: '20%' }) right;

  @Watch('chartData', { deep: true, immediate: true })
  async chartDataChange(val) {
    if (val) {
      await this.$nextTick();
      this.initChart();
    } else {
      this.chartInstance?.dispose();
      this.chartInstance = null;
    }
  }

  get options() {
    if (!this.chartData) return {};
    const total = this.chartData.reduce((a, b) => a + b.value, 0);
    /**
     * @type {echarts.EChartsCoreOption}
     */
    const option = {
      color: chartColors,
      tooltip: {
        trigger: 'item',
        borderWidth: 0,
        textStyle: {
          fontSize: nowSize(12),
          color: '#fff',
        },
        backgroundColor: 'rgba(9, 77, 143, 0.8)',
      },
      graphic: {
        elements: [
          {
            type: 'group',
            width: nowSize(140),
            height: nowSize(140),
            left: this.left,
            top: '46%',
            children: [
              // {
              //   type: 'image',
              //   id: 'pie-bg',
              //   style: {
              //     image: require('@/assets/images/smart-hat/pie-bg-1.png'),
              //     width: nowSize(140),
              //     height: nowSize(140),
              //     x: 0,
              //     y: 0,
              //   },
              // },
              {
                type: 'text',
                id: 'total',
                style: {
                  overflow: 'truncate',
                  font: `${nowSize(22)}px "Microsoft YaHei", sans-serif`,
                  // text: `总数\n${total}`,
                  text: total,
                  x: 0,
                  y: 0,
                  fill: '#6BF2FF',
                  textAlign: 'center',
                  shadowColor: 'rgba(120, 36, 50, 0.5)',
                  shadowBlur: 10,
                  shadowOffsetX: 10,
                  shadowOffsetY: 10,
                },
                left: 'center',
                top: 'center',
              },
            ],
          },
        ],
      },
      legend: {
        orient: 'horizontal',
        top: 'bottom',
        left: 'center',
        itemHeight: nowSize(5),
        itemWidth: nowSize(13),
        textStyle: {
          color: 'rgba(251,253,255,0.8)',
          fontSize: nowSize(12),
        },
      },
      series: {
        type: 'pie',
        radius: ['56%', '68%'],
        avoidLabelOverlap: false,
        center: ['50%', '50%'],
        data: this.chartData,
        itemStyle: {
          borderColor: '#0A243D',
          borderWidth: 6,
        },
        label: {
          show: true,
          formatter: '{d}%',
          textStyle: {
            color: 'rgba(251,253,255,0.8)',
          },
        },
      },
    };

    return option;
  }

  /**@type {echarts.ECharts} */
  chartInstance = null;
  initChart() {
    if (!this.chartInstance) {
      this.chartInstance = echarts.init(this.$refs.pieChatrRef);
    }

    this.chartInstance.setOption(this.options);
  }

  resize() {
    this.chartInstance.resize({
      width: 'auto',
      height: 'auto',
    });
  }

  async mounted() {
    this.initChart();
    Emitter.$on('bodyResize', this.resize);
  }
  beforeDestroy() {
    Emitter.$off('bodyResize', this.resize);
  }
}
</script>
