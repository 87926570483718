<template>
  <LayoutCard :title="$t('hat.screen.Members')">
    <!-- <template #action>
      <a-select
        class="hat-screen-card-select"
        dropdownClassName="hat-screen-card-select-dropdown"
      />
    </template> -->
    <ScatterChartIndex :chartData="chartData" />
  </LayoutCard>
</template>
<script>
import { Component } from 'vue-property-decorator';
import ScatterChartIndex from '../charts/scatter-chart.vue';
import LayoutCard from './layout-card.vue';
import { ChartComponentsMixin } from './mixins/index';
import { mixins } from 'vue-class-component';
import { focusMemberStatistics } from '@/services/smart-hat/screen';
import { focusMemberStatistics as shareData } from '@/services/smart-hat/screen-share';
import { checkHasShare } from '@/views/hat/screen/utils';

@Component({
  components: {
    ScatterChartIndex,
    LayoutCard,
  },
})
export default class FocusMembers extends mixins(ChartComponentsMixin) {
  async init() {
    try {
      const res = !checkHasShare(this.$route)
        ? await focusMemberStatistics(this.params)
        : await shareData(this.params, {
            shareId: this.$route.params.id,
            password: this.$route.params.password,
          });
      this.chartData = res;
    } catch (error) {
      throw new Error('Request exception：' + JSON.stringify(error));
    }
  }
}
</script>
<style lang="less" module></style>
