<template>
  <LayoutCard :title="$t('hat.screen.RankingAbsent')">
    <template #action>
      <a-select
        :options="option"
        v-show="showSelect"
        v-model="params.statisticsPointEnum"
        class="hat-screen-card-select"
        dropdownClassName="hat-screen-card-select-dropdown"
        @change="handleChange"
      />
    </template>
    <LineChartIndex :chartData="chartData" @click-chart="handleClickChart" />
  </LayoutCard>
</template>
<script>
import { Component, InjectReactive } from 'vue-property-decorator';
import LineChartIndex from '../charts/line-chart.vue';
import LayoutCard from './layout-card.vue';
import { departureRanking } from '@/services/smart-hat/screen';
import { departureRanking as shareData } from '@/services/smart-hat/screen-share';
import { ChartComponentsMixin } from './mixins/index';
import { mixins } from 'vue-class-component';
import { LeaveRankEnum } from '../enum/index';
import { checkHasShare } from '@/views/hat/screen/utils';
import { createModal } from '@triascloud/x-components';
import DialogPersonOffline from './dialog/dialog-person-offline.vue';
import DialogGroupOffline from './dialog/dialog-group-offline.vue';
import { nowSize } from '@/utils/common';

@Component({
  components: {
    LineChartIndex,
    LayoutCard,
  },
})
export default class DepartureRanking extends mixins(ChartComponentsMixin) {
  async init() {
    try {
      const res = !checkHasShare(this.$route)
        ? await departureRanking(this.params)
        : await shareData(this.params, {
            shareId: this.$route.params.id,
            password: this.$route.params.password,
          });
      this.chartData = {
        data: res.dataList.map(item => {
          item.tag = LeaveRankEnum[item.tag];
          item.values = item.values.map((v, idx) => {
            return {
              value: v,
              id: res.pkIdList[idx],
            };
          });
          return item;
        }),
        xAxis: res.userNameList,
      };
    } catch (error) {
      throw new Error('Request exception：' + JSON.stringify(error));
    }
  }
  get hasShare() {
    return checkHasShare(this.$route);
  }
  get shareParams() {
    return {
      shareId: this.$route.params.id,
      password: this.$route.params.password,
    };
  }
  @InjectReactive('searchForm') form;
  handleClickChart(params) {
    let Component, title, dayTitle;
    if (this.form.searchDayEnum === 'CURRENT_DAY') {
      dayTitle = '今天';
    } else if (this.form.searchDayEnum === 'SEVEN_DAY') {
      dayTitle = '近7天';
    } else if (this.form.searchDayEnum === 'THIRTY_DAY') {
      dayTitle = '近30天';
    }
    title = `${params.name}${dayTitle}离场明细`;
    if (this.params.statisticsPointEnum === 'PEOPLE') {
      Component = DialogPersonOffline;
    } else {
      Component = DialogGroupOffline;
    }
    createModal(
      () => (
        <Component
          dayEnum={this.form.searchDayEnum}
          id={params.data.id}
          hasShare={this.hasShare}
          shareParams={this.shareParams}
        />
      ),
      {
        title,
        width: nowSize(800),
      },
    );
  }
}
</script>
<style lang="less" module></style>
