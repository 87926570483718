export const LeaveRankEnum = {
  COUNT: '总次数',
  DURATION: '总时长',
};

/**
 * 时长占比
 * @enum
 * @readonly
 */
export const DurationRatioEnum = {
  LESS_ONE_HOUR: '小于等于1小时',
  ONE_FOUR_HOUR: '1到4小时',
  FOUR_EIGHT_HOUR: '4到8小时',
  MORE_EIGHT_HOUR: '大于等于8小时',
  ONE_GROUP: '一个编组',
};
