import { Component, Vue } from 'vue-property-decorator';
import { Emitter } from '@/utils';

@Component
export class ChartComponentsMixin extends Vue {
  showSelect = true;
  chartData = null;
  params = {
    statisticsPointEnum: 'PEOPLE',
    searchDayEnum: '',
    groupIdList: '',
    projectIds: '',
  };
  get option() {
    return [
      {
        label: this.$t('hat.screen.byWearer'),
        value: 'PEOPLE',
      },
      {
        label: this.$t('hat.screen.byGroup'),
        value: 'GROUP',
      },
    ];
  }

  handleChange() {
    this.init();
  }
  /**
   * @name 监听全局筛选
   * @param {object} params
   * @param {string} params.searchDayEnum
   * @param {string} params.groupIdList
   */
  searchChange(params) {
    Object.assign(this.params, {
      ...params,
      groupIdList: params.groupIdList.toString(),
      projectIds: params.projectIds,
    });
    // 单选的时候重置为‘按人’查询
    if (params.groupIdList.length === 1) {
      this.params.statisticsPointEnum = 'PEOPLE';
      this.showSelect = false;
    } else {
      this.showSelect = true;
    }
    this.init();
  }
  mounted() {
    Emitter.$on('searchChange', this.searchChange);
  }
  beforeDestroy() {
    Emitter.$off('searchChange', this.searchChange);
  }
}
