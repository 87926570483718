<template>
  <div>
    <div v-if="codeShow" :class="$style.codeBox">
      <x-qrcode
        @click.native="$preview($refs.qrcode.imageSrc, 'image')"
        :class="$style.code"
        :src="qrcodeSrc"
        :option="qrOption"
        ref="qrcode"
      ></x-qrcode>
      <a-button :class="$style.download" type="primary" @click="handleDownload"
        ><span>
          <span class="TC tc-icon-xiazai3"></span>
          <span>{{ $t('matterwebset.screenmanage.downloadtext') }}</span>
        </span>
      </a-button>
    </div>
    <div :class="$style.shareBox">
      <span :class="$style.lable"
        ><span style="color: #fff;"
          >{{ $t('matterwebset.screenmanage.linktext') }}:
        </span></span
      >
      <a-input :value="shareInfo.shareUrl" disabled :class="$style.shareUrlBox">
        <span slot="suffix" @click="handleShowQrcode">
          <i class="TC tc-icon-erweima1" :class="$style.suffix"></i>
        </span>
      </a-input>
    </div>
    <div v-if="shareInfo.password" :class="$style.shareBox">
      <span :class="$style.lable"
        ><span style="color: #fff;"
          >{{ $t('matterwebset.screenmanage.passwordtext') }}:
        </span></span
      >
      <a-input disabled :value="shareInfo.password" :class="$style.password" />
    </div>
    <div :class="$style.buttonList">
      <a-button @click="$emit('close')"> {{ $t('msg.cancel') }} </a-button>
      <a-button type="primary" @click="copy">{{
        shareInfo.password
          ? $t('matterwebset.screenmanage.copylink')
          : $t('matterwebset.screenmanage.copyShare')
      }}</a-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { clipboardWrite } from '@triascloud/utils';
import XQrcode from '@/components/common/x-qrcode/index.vue';
import XDownloadAnchor from '@/components/common/x-download-anchor/index.vue';

@Component({ components: { XQrcode } })
export default class HatShareBox extends Vue {
  @Prop({ type: Object, required: true }) shareInfo;

  codeShow = false;
  qrcodeSrc = '';
  qrOption = {
    color: {
      background: '#00000000',
      foreground: '#000000FF',
    },
    margin: 0,
  };

  handleShowQrcode() {
    this.codeShow = !this.codeShow;
    this.qrcodeSrc = this.shareInfo.mShareUrl;
  }
  handleDownload() {
    XDownloadAnchor.download(this.$refs.qrcode.imageSrc, 'qrcode.png');
  }
  async copy() {
    const { shareUrl, password } = this.shareInfo;
    let linkName = this.$t('hat.screen.linkName');
    await clipboardWrite(
      password
        ? `${linkName}: ${shareUrl} ${this.$t(
            'matterwebset.screenmanage.passwordtext',
          )}: ${password}`
        : `${linkName}: ${shareUrl}`,
    );
    this.$message.success(this.$t('camera.replicatingSuccess'));
  }
}
</script>

<style lang="less" module>
@fontColor: #05d3ff;

.projectName {
  // color: @fontColor;
  display: flex;
  .nameDetail {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.shareBox {
  margin-top: 20px;
  position: relative;
  .lable {
    width: 40px;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    line-height: 32px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .shareUrlBox {
    display: inline-block;
    width: 320px;
    input {
      padding-right: 70px !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .password {
    display: inline-block;
    width: 110px;
  }
  .suffix {
    font-size: 18px;
    display: inline-block;
    text-align: center;
    width: 30px;
    line-height: 30px;
    cursor: pointer;
    margin-right: -10px;
  }
}
.codeBox {
  position: absolute;
  height: 100%;
  width: 246px;
  z-index: 1;
  left: calc(100% + 10px);
  top: 0;
  padding: 15px 10px;
  background: var(--modal-bg);
  text-align: center;
  box-shadow: 1px 1px 10px rgba(8, 1, 3, 0.08);
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .code {
    height: 150px;
    width: 150px;
    margin-bottom: 10px;
  }
  .download {
    height: 36px;
    width: 206px;
  }
}
.buttonList {
  text-align: right;
  margin-top: 50px;
  button {
    margin-left: 20px;

    &:first-child {
      padding-right: 36px;
      padding-left: 36px;
    }
    &:last-child {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}
</style>
