<template>
  <div :class="$style.container" ref="container">
    <a-form-model
      :model="form"
      ref="form"
      hideRequiredMark
      :class="$style.form"
      validateOnRuleChange
      :rules="rules"
    >
      <a-form-model-item
        :labelCol="{ span: 4 }"
        labelAlign="left"
        :wrapperCol="{ span: 20 }"
        prop="password"
      >
        <template slot="label"
          ><span style="color: #fff;"
            >{{ $t('hat.screen.permission') }}：</span
          ></template
        >
        <a-radio-group v-model="setPassword">
          <a-radio :value="0">{{ $t('hat.screen.public') }}</a-radio>
          <a-radio :value="1">{{ $t('hat.screen.private') }}</a-radio>
        </a-radio-group>
        <a-input
          v-if="setPassword"
          @change="input"
          v-model="form.password"
          placeholder="设定密码，4位数或字母"
          :class="$style.password"
        />
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 4 }"
        labelAlign="left"
        :wrapperCol="{ span: 20 }"
        prop="expireDate"
      >
        <template slot="label"
          ><span style="color: #fff;"
            >{{ $t('hat.screen.validity') }}：</span
          ></template
        >
        <a-radio-group v-model="form.expireDate">
          <a-radio value="ONE_DAY">1{{ $t('screen.days') }}</a-radio>
          <a-radio value="SEVEN_DAY">7{{ $t('screen.days') }}</a-radio>
          <a-radio value="THIRTY_DAY">30{{ $t('screen.days') }}</a-radio>
          <a-radio value="FOR_EVER">长期</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 4 }"
        labelAlign="left"
        :wrapperCol="{ span: 20 }"
        prop="shareTitle"
      >
        <template slot="label"
          ><span style="color: #fff;">样式：</span></template
        >
        <a-radio-group v-model="form.shareTitle">
          <a-radio :value="true">默认</a-radio>
          <a-radio :value="false">无标题</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        v-if="formLabel.projectList && formLabel.projectList.length > 0"
        :labelCol="{ span: 4 }"
        labelAlign="left"
        :wrapperCol="{ span: 20 }"
        prop="shareTitle"
      >
        <template slot="label"
          ><span style="color: #fff;">项目：</span></template
        >
        <span>{{ labelShow(formLabel.projectList) }}</span>
      </a-form-model-item>
      <a-form-model-item
        v-if="formLabel.groupList && formLabel.groupList.length > 0"
        :labelCol="{ span: 4 }"
        labelAlign="left"
        :wrapperCol="{ span: 20 }"
        prop="shareTitle"
      >
        <template slot="label"
          ><span style="color: #fff;">编组：</span></template
        >
        <span>{{ labelShow(formLabel.groupList) }}</span>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { shareGenerate } from '@/services/smart-hat/screen-share';
const initForm = {
  password: '',
  expireDate: 'SEVEN_DAY',
  shareTitle: true,
};
@Component()
export default class HatShareForm extends Vue {
  @Prop() formLabel;

  labelShow(list, type = 'label') {
    return list.map(v => v[type]).toString();
  }
  form = {};
  setPassword = 0;
  get rules() {
    return {
      password: [
        {
          required: this.setPassword,
          message: '请设置密码',
          trigger: 'change',
        },
        {
          len: 4,
          message: '请设置4位数密码',
          trigger: 'change',
        },
      ],
    };
  }
  created() {
    this.form = JSON.parse(JSON.stringify(initForm));
  }
  async getValue() {
    await this.$refs.form.validate();
    const projectIds = [];
    this.formLabel.projectList.forEach(v => {
      if (v.value) {
        projectIds.push(v.value);
      }
    });
    const shareInfo = await shareGenerate({
      expireDate: this.form.expireDate,
      password: this.setPassword ? this.form.password : '',
      shareTitle: this.form.shareTitle,
      groupIdList: this.formLabel.groupList.map(v => v.value),
      projectIds,
    });
    const urls = {
      dev: `http://dev.m.${process.env.VUE_APP_HOST}/#/pages/aIot/shareInputPassword/index?id=${shareInfo.shareId}`,
      production: `https://m.${process.env.VUE_APP_HOST}/#/pages/aIot/shareInputPassword/index?id=${shareInfo.shareId}`,
      sit: `https://sit.m.${process.env.VUE_APP_HOST}/#/pages/aIot/shareInputPassword/index?id=${shareInfo.shareId}`,
      uat: `https://uat.m.${process.env.VUE_APP_HOST}/#/pages/aIot/shareInputPassword/index?id=${shareInfo.shareId}`,
    };
    return {
      ...shareInfo,
      shareUrl: `${window.location.origin}/hat/share/${shareInfo.shareId}`,
      mShareUrl: urls[process.env.VUE_APP_ENV],
    };
  }
  /**
   * @name 密码锁定只能用字母+数字4位
   */
  input({ target: { value } }) {
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    value = value.substring(0, 4);
    this.form.password = value;
    this.$refs.form.validateField('password');
  }
}
</script>

<style lang="less" module>
.container {
  font-size: 14px;
  padding-bottom: 30px;
  :global {
    input[type='text'] {
      font-size: 14px;
      margin-left: unset;
      width: 200px;
    }
    .ant-radio-wrapper {
      width: 100px;
    }
  }

  .formItem {
    display: inline-block;
    width: 26.59%;

    :global(.ant-col.ant-form-item-control-wrapper) {
      float: right;
      width: 90%;
    }
    &:first-child {
      width: 40%;
      :global(.ant-col.ant-form-item-control-wrapper) {
        width: 60%;
      }
    }
  }
  .buttonList {
    text-align: right;
    margin-top: 130px;
  }
  .inSelect {
    width: 160px;
  }
  .password {
    width: 174px;
    margin-left: 20px;
  }
}
</style>
