<template>
  <a-table
    :columns="columns"
    :data-source="tableData"
    :pagination="pagination"
    :row-key="record => record.deviceId"
    :loading="loading"
    @change="handlePagination"
  >
  </a-table>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { msgPageDetail } from '@/services/smart-hat/device';
import { sharePageDetail } from '@/services/smart-hat/screen-share';
import { clipboardWrite } from '@triascloud/utils';
import { messageType } from '@/views/hat/enum.js';
import { nowSize } from '@/utils/common';
@Component()
export default class DialogDeviceEvents extends Vue {
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  loading = false;
  tableData = [];
  copyPhone(val) {
    clipboardWrite(val).then(() => {
      this.$message.success('复制成功');
    });
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('hat.clockRecord.index'),
        align: 'center',
        width: nowSize(80),
      },
      {
        align: 'left',
        title: '时间',
        dataIndex: 'messageTime',
        ellipsis: true,
        customRender: text =>
          text ? this.$moment(text).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: '事件类型',
        dataIndex: 'subType',
        ellipsis: true,
        customRender: text => (text ? messageType[text] : '-'),
      },
      {
        align: 'left',
        title: '位置',
        dataIndex: 'lat',
        width: nowSize(240),
        ellipsis: true,
        customRender: (_, record) =>
          record.lat ? (
            <span>
              <a-icon type="environment" />
              {record.lng},{record.lat}
            </span>
          ) : (
            '-'
          ),
      },
      {
        title: '佩戴人员',
        dataIndex: 'userName',
        ellipsis: true,
        customRender: (text, record) => (
          <div>
            <a-tooltip placement="top">
              <template slot="title">
                <div>
                  {record.roleList.length > 0 ? (
                    <div>
                      {this.$t('iotScreenManage.role')}：
                      {this.roleNames(record.roleList)}
                    </div>
                  ) : null}

                  <div
                    onClick={() => {
                      this.copyPhone(record.mobilePhone);
                    }}
                  >
                    {this.$t('enterpriseManagement.camera.tableColumn.phone')}：
                    {record.mobilePhone}
                  </div>
                </div>
              </template>
              <span>{record.userName}</span>
            </a-tooltip>
          </div>
        ),
      },
      {
        title: '事件明细',
        dataIndex: 'content',
        ellipsis: true,
        width: nowSize(300),
        // scopedSlots: { customRender: 'contentSlot' },
      },
    ];
  }
  @Prop({ type: Object }) form;
  @Prop({ type: Object }) shareParams;
  @Prop({ type: Boolean }) hasShare;
  async getData() {
    this.loading = true;
    try {
      const params = {
        searchDayEnum: this.form.searchDayEnum,
        groupIdList: this.form.groupIdList.toString(),
        projectId: this.form.projectIds,
        current: this.pagination.current,
        size: this.pagination.pageSize,
      };
      const { records, total } = this.hasShare
        ? await sharePageDetail(params, this.shareParams)
        : await msgPageDetail(params);
      this.tableData = records;
      this.pagination.total = total;
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
  roleNames(list) {
    let str = list.reduce((v, a) => v + a.roleName + '、', '');
    if (str.slice(-1) === '、') {
      str = str.slice(0, -1);
    }
    return str;
  }
  handlePagination(data) {
    this.pagination = data;
    this.getData();
  }
  mounted() {
    this.getData();
  }
}
</script>
<style lang="less" module></style>
